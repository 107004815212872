<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('meetingManagement.search_parameter') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
            <b-col xs="4" sm="4" md="4">
              <b-form-group
                label-for="document_type"
              >
                <template v-slot:label>
                  {{ $t('configuration.document_type') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.document_type_id"
                  :options="docTypeList"
                  id="document_type"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{ $t('globalTrans.select') }}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="4" sm="4" md="4">
              <b-form-group
                label-for="document_id"
              >
                <template v-slot:label>
                  {{ $t('globalTrans.title') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.document_id"
                  :options="documentList"
                  id="document_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="4" sm="4" md="4">
              <b-form-group
                label-for="designation_id"
              >
                <template v-slot:label>
                  {{$t('globalTrans.designation')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.designation_id"
                  :options="designationList"
                  id="designation_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="4" sm="4" md="4">
              <b-form-group
                label-for="employee_user_id"
              >
                <template v-slot:label>
                  {{ $t('research_plan_management.responsible_person') }}
                </template>
                <b-form-select
                  plain
                  v-model="search.employee_user_id"
                  :options="employeeList"
                  id="employee_user_id"
                >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
          <b-col xs="4" sm="4" md="4" >
           <b-form-group
              label-for="date_from">
              <template v-slot:label>
                {{ $t('globalTrans.date_from') }}
              </template>
              <date-picker
                id="date_from"
                v-model="search.date_from"
                class="form-control"
                :placeholder="$t('externalTradeFair.date_from')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col xs="4" sm="4" md="4" >
           <b-form-group
              label-for="date_to">
              <template v-slot:label>
                {{ $t('globalTrans.date_to') }}
              </template>
              <date-picker
                id="date_to"
                v-model="search.date_to"
                class="form-control"
                :placeholder="$t('externalTradeFair.date_to')"
              >
              </date-picker>
            </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="mt-20" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <div v-if="showData">
      <b-overlay :show="loading">
        <body-card>
          <!-- table section start -->
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('task_activity_monitoring.task_activity_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn-add" variant=" iq-bg-info" @click="pdfExport">
                {{ $t('globalTrans.print') }}
            </b-button>
            <export-excel
                class="btn btn-add btn-success ml-2"
                :data="excelData"
                :title="headerValue"
                :fields=excelFields
                worksheet="Report Sheet"
                :default-value="headerExcelDefault"
                name="task-activity-report.xls">
              {{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
              <list-report-head :base-url="bftiResReportServiceBaseUrl" uri="/configuration/report-head/detail" :org-id="10">
                <template v-slot:projectNameSlot>
                  {{ }}
                </template>
                {{ $t('task_activity_monitoring.task_activity_report') }}
              </list-report-head>
              <b-row>
                <b-col md="4">
                  <span>{{ $t('configuration.document_type') }}</span>:
                  <strong>{{ searchHeaderData.document_type_id ? getDocumentTypeName(searchHeaderData.document_type_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4">
                  <span>{{ $t('globalTrans.title') }}</span>:
                  <strong>{{ searchHeaderData.document_id ? getDocumentName(searchHeaderData.document_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4">
                  <span>{{ $t('globalTrans.designation') }}</span>:
                  <strong>{{ searchHeaderData.designation_id ? getDesignationName(searchHeaderData.designation_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4">
                  <span>{{ $t('research_plan_management.responsible_person') }}</span>:
                  <strong>{{ searchHeaderData.employee_user_id ? getUserName(searchHeaderData.employee_user_id) : $t('globalTrans.all') }}</strong>
                </b-col>
                <b-col md="4">
                <span>{{ $t('externalTradeFair.date_from') }}</span>:
                  <strong>
                    <slot v-if="searchHeaderData.date_from">
                      {{ searchHeaderData.date_from | dateFormat }}
                    </slot>
                    <slot v-else>
                      {{ $t('globalTrans.all') }}
                    </slot>
                  </strong>
                </b-col>
                <b-col md="4">
                <span>{{ $t('externalTradeFair.date_to') }}</span>:
                  <strong>
                    <slot v-if="searchHeaderData.date_to">
                      {{ searchHeaderData.date_to | dateFormat }}
                    </slot>
                    <slot v-else>
                      {{ $t('globalTrans.all') }}
                    </slot>
                  </strong>
                </b-col>
              </b-row>
              <b-table-simple class="tg mt-3" bordered striped hover small caption-top responsive :emptyText="$t('globalTrans.noDataFound')">
                  <b-thead>
                      <b-tr>
                        <b-th style="width:7%" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="width:10%" class="text-center">{{ $t('task_activity_monitoring.task_name') }}</b-th>
                            <b-th style="width:13%" class="text-center">{{ $t('task_activity_monitoring.sub_task_name') }}</b-th>
                            <b-th style="width:10%" class="text-center">
                              {{ $t('research_plan_management.responsible_person') }}
                              {{ $t('globalTrans.and') }} {{ $t('globalTrans.designation') }}
                              </b-th>
                            <b-th style="width:13%" class="text-center">
                              {{ $t('task_activity_monitoring.assigned_start_date') }} {{ $t('globalTrans.and') }}
                              {{ $t('task_activity_monitoring.assigned_end_date') }}
                            </b-th>
                            <b-th style="width:13%" class="text-center">
                              {{ $t('task_activity_monitoring.actual_start_date') }} {{ $t('globalTrans.and') }}
                              {{ $t('task_activity_monitoring.actual_end_date') }}
                            </b-th>
                            <b-th style="width:13%" class="text-center">{{ $t('task_activity_monitoring.current_status') }}</b-th>
                            <b-th style="width:9%" class="text-center">{{ $t('task_activity_monitoring.review_status') }}</b-th>
                      </b-tr>
                  </b-thead>
                  <b-tbody>
                      <template v-if="reportData.length">
                          <b-tr v-for="(item, index) in reportData" :key="index">
                                <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                <b-td class="text-center">{{ getTaskName(item.task_heading_id) }}</b-td>
                                <b-td class="text-center">{{ getSubTaskName(item.sub_task_heading_id) }}</b-td>
                                <b-td class="text-center">
                                  {{ getUserName(item.employee_user_id) }} <br>
                                  {{ getDesignationName(item.designation_id) }}
                                </b-td>
                                <b-td class="text-center">
                                  {{ item.start_date | dateFormat }} -
                                  {{ item.end_date | dateFormat }}
                                </b-td>
                                <b-td class="text-center">
                                  {{ item.actual_start_date | dateFormat }} -
                                  {{ item.actual_end_date | dateFormat }}
                                </b-td>
                                <b-td class="text-center">
                                  <span class="badge badge-primary" v-if="item.status === 1">{{$t('globalTrans.pending')}}</span>
                                  <span class="badge badge-info" v-else-if="item.status === 2">{{$t('task_activity_monitoring.ongoing')}}</span>
                                  <span class="badge badge-success" v-else-if="item.status === 3">{{$t('task_activity_monitoring.done')}}</span>
                                </b-td>
                                <b-td class="text-center">
                                  <span class="badge badge-primary" v-if="item.task_type === 1">{{$t('globalTrans.no')}}</span>
                                  <span class="badge badge-info" v-else-if="item.task_type === 2">{{$t('globalTrans.yes')}}</span>
                                </b-td>
                          </b-tr>
                      </template>
                      <template v-else>
                          <b-tr>
                              <b-td class="text-center" colspan="8">{{ $t('globalTrans.noDataFound') }}</b-td>
                          </b-tr>
                      </template>
                  </b-tbody>
              </b-table-simple>
          </template>
          <!-- table section end -->
        </body-card>
     </b-overlay>
    </div>
  </div>
</template>
<script>
import RestApi, { authServiceBaseUrl, bftiResReportServiceBaseUrl } from '@/config/api_config'
import { userListByDesignWise, taskActivityReport } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export_pdf_list'
import excel from 'vue-excel-export'
import { dateFormat } from '@/utils/fliter'
import Vue from 'vue'
Vue.use(excel)

export default {
  components: {
    ListReportHead
  },
  data () {
    return {
      bftiResReportServiceBaseUrl: bftiResReportServiceBaseUrl,
      sortBy: '',
      search: {
        document_type_id: 0,
        document_id: 0,
        designation_id: 0,
        employee_user_id: 0,
        program_type_id: 0,
        date_from: '',
        date_to: ''
      },
      searchHeaderData: {
        document_type_id: 0,
        document_id: 0,
        designation_id: 0,
        employee_user_id: 0,
        program_type_id: 0,
        date_from: '',
        date_to: ''
      },
      editItemId: 0,
      item: {},
      showData: false,
      reportData: [],
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      },
      documentList: [],
      allUserList: [],
      employeeList: []
    }
  },
  created () {
    this.headerDataExcel()
    this.getAllUserList()
  },
  watch: {
    'search.document_type_id': function (newVal, oldVal) {
      this.documentList = newVal ? this.getDocumentList(newVal) : []
    },
    'search.designation_id': function (newVal, oldVal) {
      this.employeeList = newVal ? this.getUserList(newVal) : []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    docTypeList () {
      return this.$store.state.BftiResearchAndReportService.commonObj.documentTypeList.filter(item => item.status === 1)
    },
    programTypeList () {
        return this.$store.state.BftiResearchAndReportService.commonObj.programTypeList.filter(item => item.status === 1)
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgName
        headerVal[2] = this.headerExcelDefault.address
        headerVal[3] = this.$t('task_activity_monitoring.task_activity_report')
        headerVal[4] = ''
      } else {
        headerVal[0] = this.$t('globalTrans.government_republic_of_bangladesh')
        headerVal[1] = this.headerExcelDefault.orgNameBn
        headerVal[2] = this.headerExcelDefault.address_bn
        headerVal[3] = this.$t('task_activity_monitoring.task_activity_report')
        headerVal[4] = ''
      }
      const docType = this.$t('configuration.document_type') +
          ' : ' + (this.search.document_type_id ? this.getDocumentTypeName(this.search.document_type_id) : this.$t('globalTrans.all')) + ' '
      const title = this.$t('globalTrans.title') + ' : ' + (this.search.document_id ? this.getDocumentName(this.search.document_id) : this.$t('globalTrans.all')) + ' '
      const designation = this.$t('globalTrans.designation') + ' : ' + (this.search.designation_id ? this.getDesignationName(this.search.designation_id) : this.$t('globalTrans.all'))
      const uName = this.getUserName(this.search.employee_user_id)
      const userName = this.$t('research_plan_management.responsible_person') + ' : ' + (this.search.employee_user_id ? (!(uName === null || uName === undefined) ? uName : ' ') : this.$t('globalTrans.all')) + ' '
      const dateFrom = this.$t('externalTradeFair.date_from') + ' : ' + (this.search.date_from ? dateFormat(this.search.date_from) : this.$t('globalTrans.all')) + ';' + ' ' + ' ' + ' ' + ' '
      const endDate = this.$t('externalTradeFair.date_to') + ' : ' + (this.search.date_to ? dateFormat(this.search.date_to) : this.$t('globalTrans.all')) + ';' + ' ' + ' ' + ' ' + ' '
      headerVal[5] = docType + title + designation
      headerVal[6] = userName + dateFrom + endDate
      return headerVal
    },
    excelFields: function () {
      if (this.$i18n.locale === 'bn') {
        return {
          // ' ': 'space',
          'ক্রমিক নং': 'serial',
          'টাস্কের নাম': 'task_heading_id',
          'সাব টাস্কের নাম': 'sub_task_heading_id',
          'দায়িত্বপ্রাপ্ত ব্যক্তি ও পদবী': 'responsible_person_designation',
          'নির্ধারিত শুরুর তারিখ ও নির্ধারিত শেষের তারিখ': 'assigned_start_end_date',
          'প্রকৃত শুরুর তারিখ ও প্রকৃত শেষের তারিখ': 'actual_start_end_date',
          'এখনকার অবস্থা': 'status',
          'পর্যালোচনা অবস্থা': 'task_type'
        }
      } else {
        return {
          // ' ': 'space',
          'SL No': 'serial',
          'Task Name': 'task_heading_id',
          'Sub Task Name': 'sub_task_heading_id',
          'Resposnsible Person & Designation': 'responsible_person_designation',
          'Assigned Start Date & Assigned End Date': 'assigned_start_end_date',
          'Actual Start Date & Actual End Date': 'actual_start_end_date',
          'Current Status': 'status',
          'Review Status': 'task_type'
        }
      }
    },
    excelData: function () {
      const excelData = []
      this.reportData.forEach((item, index) => {
        const subTask = this.getSubTaskName(item.sub_task_heading_id)
        const userName = this.getUserName(item.employee_user_id)
        const designation = this.getDesignationName(item.designation_id)
        excelData.push({
          serial: this.$n(index + 1),
          task_heading_id: this.getTaskName(item.task_heading_id),
          sub_task_heading_id: !(subTask === null || subTask === undefined) ? subTask : ' ',
          responsible_person_designation: (!(userName === null || userName === undefined) ? userName : ' ') + '\n' + (!(designation === null || designation === undefined) ? designation : ' '),
          assigned_start_end_date: dateFormat(item.start_date) + '-' + dateFormat(item.end_date),
          actual_start_end_date: dateFormat(item.actual_start_date) + '-' + dateFormat(item.actual_end_date),
          status: item.status === 1 ? this.$t('globalTrans.pending') : (item.status === 2 ? this.$t('task_activity_monitoring.ongoing') : this.$t('task_activity_monitoring.done')),
          task_type: item.task_type === 1 ? this.$t('globalTrans.no') : this.$t('globalTrans.yes')
        })
      })
      return excelData
    },
    designationList: function () {
      const listObject = this.$store.state.CommonService.commonObj.componentDesignationList.filter(item => item.org_id === 10)
      const tmpList = listObject.map((obj, index) => {
        return { value: obj.value, text: this.currentLocale === 'en' ? obj.text_en : obj.text_bn }
      })
      return tmpList
    }
  },
  methods: {
    getAllUserList () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      this.userLoading = true
      RestApi.getData(authServiceBaseUrl, userListByDesignWise).then(response => {
        if (response.success) {
          this.allUserList = response.data
          // this.loadData()
        } else {
          this.$store.dispatch('mutateCommonProperties', { loading: false })
        }
      })
    },
    details (item) {
      this.item = item
      this.editItemId = item.id
    },
    getTotal (items) {
        let totalprice = 0
        Object.entries(items).forEach(([key, value]) => {
            totalprice = totalprice + parseInt(value.total_participant)
        })
        return totalprice
    },
    getUserList (designationId) {
      const userList = this.allUserList.filter(item => item.designation_id === designationId)
      if (userList.length > 0) {
         const tmpList = userList.map((obj, index) => {
          return { value: obj.id, text: this.currentLocale === 'en' ? obj.name : obj.name_bn }
        })
        return tmpList
      } else {
        return []
      }
    },
    getUserName (id) {
      const obj = this.allUserList.find(obj => obj.id === parseInt(id))
      return this.currentLocale === 'en' ? obj?.name : obj?.name_bn
    },
    searchData () {
      this.loadData()
    },
    getProgramName (rogramTypeId) {
    const data = this.$store.state.BftiResearchAndReportService.commonObj.programTypeList.find(doc => doc.value === rogramTypeId)
    return data !== undefined ? data.text : ''
    },
    getDocumentList (ducumentTypeId) {
      let list = []
      if (ducumentTypeId === 1) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.eoiList
      } else if (ducumentTypeId === 2) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.rfpList
      } else if (ducumentTypeId === 4) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.policyList
      } else {
        list = this.$store.state.BftiResearchAndReportService.commonObj.researchRequestList
      }
      return list
    },
    getTaskName (id) {
      const obj = this.$store.state.BftiResearchAndReportService.commonObj.taskHeadingList.find(obj => obj.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getSubTaskName (id) {
      const obj = this.$store.state.BftiResearchAndReportService.commonObj.taskSubHeadingList.find(obj => obj.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDesignationName (id) {
      const obj = this.$store.state.CommonService.commonObj.componentDesignationList.find(obj => obj.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDocumentTypeName (id) {
      const obj = this.docTypeList.find(obj => obj.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDocumentName (id) {
      let list = []
      if (this.search.document_id === 1) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.eoiList
      } else if (this.search.document_id === 2) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.rfpList
      } else if (this.search.document_id === 4) {
        list = this.$store.state.BftiResearchAndReportService.commonObj.policyList
      } else {
        list = this.$store.state.BftiResearchAndReportService.commonObj.researchRequestList
      }
      const obj = list.find(obj => obj.value === parseInt(id))
      return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    headerDataExcel () {
      RestApi.getData(bftiResReportServiceBaseUrl, '/configuration/report-head/detail/10').then(response => {
        if (response.success) {
          const orgList = this.$store.state.CommonService.commonObj.componentOrgList.find(item => item.value === 11)
          const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
          const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
          this.headerExcelDefault.orgName = orgName
          this.headerExcelDefault.orgNameBn = orgNameBn
          this.headerExcelDefault.address = response.data.address
          this.headerExcelDefault.address_bn = response.data.address_bn
        }
      })
    },
    pdfExport () {
        const reportTitle = this.$t('task_activity_monitoring.task_activity_report')
        ExportPdf.exportPdfDetails(bftiResReportServiceBaseUrl, '/configuration/report-head/detail', 10, reportTitle, this)
    },
    pdfExportDetails () {
      this.$refs.details.pdfExport()
    },
    async loadData () {
      this.showData = true
      if (this.search.document_type_id) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          document_type_id: this.search.document_type_id
        })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          document_type_id: 0
        })
      }
      if (this.search.document_id) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          document_id: this.search.document_id
        })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          document_id: 0
        })
      }
      if (this.search.designation_id) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          designation_id: this.search.designation_id
        })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          designation_id: 0
        })
      }
      if (this.search.employee_user_id) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          employee_user_id: this.search.employee_user_id
        })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, {
          employee_user_id: 0
        })
      }
      if (this.search.date_from) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, { date_from: this.search.date_from })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, { date_from: '' })
      }
      if (this.search.date_to) {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, { date_to: this.search.date_to })
      } else {
        this.searchHeaderData = Object.assign({}, this.searchHeaderData, { date_to: '' })
      }
      const params = Object.assign({}, this.search)
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const result = await RestApi.getData(bftiResReportServiceBaseUrl, taskActivityReport, params)
      if (result.success) {
        const listData = result.data.map(item => {
          const rogramType = this.programTypeList.find(obj => obj.value === item.program_type_id)
          const customItem = {
            rogram_type_en: rogramType?.text_en,
            rogram_type_bn: rogramType?.text_bn
          }
          return Object.assign({}, item, customItem)
        })
        this.reportData = listData
      } else {
          this.reportData = []
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false })
    }
  }
}
</script>

<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
